import React from "react";
import {Modal, Divider, Progress, Button} from "antd";

import {tool} from "../tools/tools";
import {connect} from "react-redux";
import $ from "jquery";


//数据更新 tool.show_success
class UpdateTableLine extends React.Component {
    constructor(props) {
        super(props);
        this.getColumnsUpdate = this.getColumnsUpdate.bind(this);
    }

    getFieldReact(param) {
        let columnKey = tool.getAttribute(param, 'columnKey');
        let defaultValue = tool.getAttribute(param, 'defaultValue');

        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        // let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(infoModal, 'record');
        // let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        // let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        // let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsUpdate = this.getColumnsUpdate();
        let columnInfo = columnsUpdate[columnKey];


        //字段类型
        let fieldType = columnInfo.type;
        if (!fieldType) fieldType = 'char';


        let fieldMapping = tool.getStateSpace(['setting', 'fieldMapping',]);
        let func = tool.getAttribute(fieldMapping, fieldType);

        return (
            <div key={columnKey}>
                {func({
                    record: record,
                    action: 'update',
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                })}
                <Divider/>
            </div>
        );
    }

    getColumnsUpdate() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Update') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        //更新字段
        let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate',]);
        let columnsUpdate = tool.getStateSpace(columnsUpdatePath);

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumnsUpdate = tool.getAttribute(filterNow, 'columnsUpdate');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumnsUpdate) {
            columnsUpdate = filterColumnsUpdate;
        }
        return columnsUpdate;
    }

    getContent() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Update') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, ['record']);


        //更新字段
        let columnsUpdate = this.getColumnsUpdate();

        //显示字段
        if (!columnsUpdate) return null;

        let fieldReacts = [];
        for (let columnKey in columnsUpdate) {
            if (!columnsUpdate.hasOwnProperty(columnKey)) continue;

            //具体的升级列信息
            let columnUpdate = tool.getAttribute(columnsUpdate, columnKey,);

            //默认值 defaultValue
            let defaultValue = tool.getAttribute(columnUpdate, 'defaultValue',);

            //默认值映射列mapColumnKey
            let mapColumnKey = tool.getAttribute(columnUpdate, 'map',);
            if (!mapColumnKey) mapColumnKey = columnKey;

            //映射值
            let mapValue = record[mapColumnKey];
            if (!defaultValue) {
                defaultValue = mapValue;
            }


            //决定是否添加这个字段
            let state = true;
            let fun_is_show = tool.getAttribute(columnUpdate, 'is_show');
            if (fun_is_show) {
                let param = {infoModalPath: infoModalPath, mapColumnKey: mapColumnKey, record: record,};
                state = fun_is_show(param);
            }
            if (state) {
                fieldReacts.push(this.getFieldReact({
                    columnKey: columnKey,
                    defaultValue: defaultValue,
                }));
            }
        }

        return fieldReacts;
    }

    getProgress() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Update') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let progressInfo = tool.getAttribute(spacePrivate, 'progressInfo');
        let stateUpdateUpload = tool.getAttribute(spacePrivate, 'stateUpdateUpload');
        if (!stateUpdateUpload) return null;

        let percent = tool.getAttribute(progressInfo, 'percent');
        if (!percent) percent = 0;
        return <div>
            <Progress percent={percent} status="active"/>
        </div>;
    }

    onCancel() {
        //左键触发检测程序允许正常运行
        tool.rightInfo = Object.assign({}, tool.rightInfo, {
            sign: false,
        },);

        //关闭对话框/清空数据
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        infoModal = Object.assign({}, infoModal, {
            nowType: null,
            paramModal: null,
        });

        tool.setStateSpace(infoModalPath, infoModal,);
    }

    onOk() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Update') return null;
        let paramModal = tool.getAttribute(infoModal, 'paramModal');
        let record = tool.getAttribute(paramModal, 'record');
        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let fatherRecord = tool.getAttribute(paramModal, 'fatherRecord');
        let requestPathPath = spacePublicPath.concat(['path']);
        let requestPath = tool.getStateSpace(requestPathPath);

        let dataKey = `update__${record.id}`;
        let dataPath = infoModalPath.concat(['paramModal', dataKey]);
        let data = tool.getStateSpace(dataPath);
        if (!data) data = {};

        // 获取get参数
        let paramUrl = {};
        if (fatherRecord) paramUrl['father_id'] = fatherRecord.id;

        let url = tool.get_url(requestPath, false, paramUrl, record.id);

        let stateSplitTwo = false;
        let dataObj;

        // 设置为正在上传
        let stateUpdateUploadPath = spacePrivatePath.concat(['stateUpdateUpload']);
        tool.setStateSpace(stateUpdateUploadPath, true);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        //把数据分成两部分
        let otherData = {};
        let filesData = {};
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            let value = data[key];
            if (value instanceof File) {
                filesData[key] = value;
            } else {
                otherData[key] = value;
            }

        }
        otherData = JSON.stringify(otherData);
        if (JSON.stringify(filesData) !== '{}') {
            stateSplitTwo = true;
            dataObj = new FormData();
            dataObj.append('stateSplitTwo', 'true');

            dataObj.append('otherData', otherData);
            for (let fileKey in filesData) dataObj.append(`files__${fileKey}`, filesData[fileKey]);
        }

        // 置空进度条
        let percent = 0; //进度值（百分比制）
        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
        tool.setStateSpace(percentPath, percent);
        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);

        let requestParam = {
            url: url,
            data: otherData,
            contentType: 'application/json',
            success: (data, _, response,) => {
                let status = tool.getAttribute(response, 'status');
                if (status === 299) {
                    tool.show_success_299(data);
                } else if (status === 298) {
                    tool.show_success_298(data);
                } else {
                    tool.show_success(['更新成功',]);
                }

                // 清空表单数据
                let infoModalPath = ['infoModal'];
                let infoModal = tool.getStateSpace(infoModalPath);
                infoModal = Object.assign({}, infoModal, {
                    nowType: null,
                    paramModal: null,
                });
                tool.setStateSpace(infoModalPath, infoModal,);

                // 更新表格数据
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: 'page',
                    stateUpdateUpload: false,
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            },
            error: data => {
                let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
                let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
                tool.show_error(data, columnsUpdate);
                tool.setStateSpace(stateUpdateUploadPath, false);
            },
            xhr: function () { //用以显示上传进度
                let xhr = $.ajaxSettings.xhr();
                if (xhr.upload) {
                    xhr.upload.addEventListener('progress', function (event) {
                        let percent = Math.floor(event.loaded / event.total * 100); //进度值（百分比制）
                        let percentPath = spacePrivatePath.concat(['progressInfo', 'percent',]);
                        tool.setStateSpace(percentPath, percent);
                        tool.setStateSpace(infoModalPath, Object.assign({}, infoModal),);
                    }, false);
                }
                return xhr
            },
        };

        //数据分成两部分
        if (stateSplitTwo) {
            requestParam['contentType'] = false;
            requestParam['processData'] = false;
            requestParam['data'] = dataObj;
        }
        tool.patch(requestParam);
    }

    render() {
        let infoModalPath = ['infoModal'];
        let infoModal = tool.getStateSpace(infoModalPath);
        let nowType = tool.getAttribute(infoModal, 'nowType');
        if (nowType !== 'Update') return null;

        let paramModal = tool.getAttribute(infoModal, 'paramModal');

        let spacePublicPath = tool.getAttribute(paramModal, 'spacePublicPath');

        // 是否自定义的触发器
        // let columnsUpdatePath = spacePublicPath.concat(['columnsUpdate']);
        // let columnsUpdate = tool.getStateSpace(columnsUpdatePath);
        let columnsUpdate = this.getColumnsUpdate();
        let react = tool.getAttribute(columnsUpdate, 'react__');
        if (react) {
            let param;
            // console.log('??>>',this.props)
            param = Object.assign({}, this.props);
            return react(param);
        }


        let textTitlePath = spacePublicPath.concat(['text__update_title']);
        let textTitle = tool.getStateSpace(textTitlePath);
        if (!textTitle) textTitle = '数据更新';

        let content = this.getContent();
        let progress = this.getProgress();

        // 是否正在提交数据
        let spacePrivatePath = tool.getAttribute(paramModal, 'spacePrivatePath');
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let stateUpdateUpload = tool.getAttribute(spacePrivate, 'stateUpdateUpload');
        let disabled = false;
        if (stateUpdateUpload) disabled = true;

        return (

            <div>
                <Modal
                    title={textTitle}
                    visible={true}
                    okText={'提交'}
                    cancelText={'关闭'}
                    onOk={() => this.onOk()}
                    onCancel={() => this.onCancel()}
                    footer={<div>
                        <Button onClick={() => this.onCancel()}>关闭</Button>
                        <Button
                            onClick={() => this.onOk()}
                            type={'primary'}
                            disabled={disabled}
                        >提交</Button>
                    </div>}
                >
                    {content}
                    {progress}
                </Modal>
            </div>
        );
    }
}

function mapStateToUpdate(state) {
    return {
        infoModal: state.infoModal,
    }
}

UpdateTableLine = connect(mapStateToUpdate)(UpdateTableLine);

export {UpdateTableLine};