import React from "react";


//编辑映射字段
import {FieldChar} from "./apps/power-table/fields/char/char";
import {FieldText} from "./apps/power-table/fields/text/text";
import {FieldMultipleChoices} from "./apps/power-table/fields/multiple-choices/multiple-choices";
import {FieldSingleSmallChoices} from "./apps/power-table/fields/single-small-choices/single-small-choices";
import {FieldSingleDeepChoices} from "./apps/power-table/fields/single-deep-choices/single-deep-choices";
import {FieldFile} from "./apps/power-table/fields/file/file";
import {FieldMultipleFile} from "./apps/power-table/fields/multiple-file/multiple-file";
import {FieldMultipleImage} from "./apps/power-table/fields/multiple-img/multiple-img";
import {FieldBool} from "./apps/power-table/fields/bool/bool";
import {FieldImagePath} from "./apps/power-table/fields/img-path/img-path";
import {FieldImageShow} from "./apps/power-table/fields/img-show/img-show";
import {FieldImageHead} from "./apps/power-table/fields/img-head/img-head";
import {FieldDynamic} from "./apps/power-table/fields/dynamic/dynamic";
import {FieldTime} from "./apps/power-table/fields/time/time";
import {FieldTimeYear} from "./apps/power-table/fields/time-year/time";
import {FieldTimeDay} from "./apps/power-table/fields/time-day/time-day";
import {FieldSingleSearchChoices} from "./apps/power-table/fields/single-search-choices/single-search-choices";


//详情映射字段
import {FieldDetailChar} from "./apps/power-table/coumns-detail/char/char";
import {FieldDetailMoney} from "./apps/power-table/coumns-detail/money/money";
import {FieldDetailHelpArray} from "./apps/power-table/coumns-detail/help-array/help-array";
import {FieldDetailBool} from "./apps/power-table/coumns-detail/bool/bool";
import {FieldDetailImgPath} from "./apps/power-table/coumns-detail/img-path/img-path";
import {FieldDetailLabel} from "./apps/power-table/coumns-detail/label/label";
import {FieldDetailLabelHelp} from "./apps/power-table/coumns-detail/label/label-help";
import {FieldDetailDynamic} from "./apps/power-table/coumns-detail/dynamic/dynamic";
import {FieldDetailFile} from "./apps/power-table/coumns-detail/file/file";
import {FieldDetailMap} from "./apps/power-table/coumns-detail/map/map";
import {FieldDetailRender} from "./apps/power-table/coumns-detail/render/render";
import {FieldDetailChinaTime} from "./apps/power-table/coumns-detail/chinaTime/china-time";
import {FieldDetailYearTime} from "./apps/power-table/coumns-detail/yearTime/year-time";
import {FieldDetailUtcTime} from "./apps/power-table/coumns-detail/utcTime/utc-time";
import {FieldDetailUserInfo} from "./apps/power-table/coumns-detail/userInfo/char";


// 编辑字段映射
let fieldMapping = {
    bool: param => <FieldBool param={param}/>,
    char: param => <FieldChar param={param}/>,
    text: param => <FieldText param={param}/>,
    file: param => <FieldFile param={param}/>,
    multipleFile: param => <FieldMultipleFile param={param}/>,
    multipleChoice: param => <FieldMultipleChoices param={param}/>,
    SingleSmallChoice: param => <FieldSingleSmallChoices param={param}/>,
    singleSmallChoice: param => <FieldSingleSmallChoices param={param}/>,
    singSearchChoice: param => <FieldSingleSearchChoices param={param}/>,
    singDeepChoice: param => <FieldSingleDeepChoices param={param}/>,
    imgPath: param => <FieldImagePath param={param}/>,
    imgShow: param => <FieldImageShow param={param}/>,
    imgHead: param => <FieldImageHead param={param}/>,
    multipleImg: param => <FieldMultipleImage param={param}/>,
    dynamic: param => <FieldDynamic param={param}/>,
    time: param => <FieldTime param={param}/>,
    timeYear: param => <FieldTimeYear param={param}/>,
    timeDay: param => <FieldTimeDay param={param}/>,
};


// 详情字段映射
let fieldDetailMapping = {
    char: param => <FieldDetailChar param={param}/>,
    money: param => <FieldDetailMoney param={param}/>,
    map: param => <FieldDetailMap param={param}/>,
    render: param => <FieldDetailRender param={param}/>,
    chinaTime: param => <FieldDetailChinaTime param={param}/>,
    yearTime: param => <FieldDetailYearTime param={param}/>,
    utcTime: param => <FieldDetailUtcTime param={param}/>,
    helpArray: param => <FieldDetailHelpArray param={param}/>,
    bool: param => <FieldDetailBool param={param}/>,
    imgPath: param => <FieldDetailImgPath param={param}/>,
    imgShow: param => <FieldDetailImgPath param={param}/>,
    imgHead: param => <FieldDetailImgPath param={param}/>,
    label: param => <FieldDetailLabel param={param}/>,
    labelHelp: param => <FieldDetailLabelHelp param={param}/>,
    dynamic: param => <FieldDetailDynamic param={param}/>,
    file: param => <FieldDetailFile param={param}/>,
    userInfo: param => <FieldDetailUserInfo param={param}/>,
};


export {fieldMapping, fieldDetailMapping}

