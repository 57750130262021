import {fieldMapping, fieldDetailMapping,} from "./public_setting";
import {tool} from "./apps/tools/tools";
import {GeneLogin} from "./apps/gene/login/gene-login";
// import {Bottom} from "./apps/bottom/bottom";

const conf = [
    ['输入你的系统帐号', 'Enter your system account number.',],
    ['spike20系统后台', 'spike20.',],
    ['输入你的密码', 'Enter your password.',],
    ['登录', 'Login',],
];

function language() {
    return 1;
}

function conf2mapping() {
    // 仅运行一次, 转化为符合js语法并可以哈希查询的格式
    let mapping = {};
    for (const line of conf) {
        let key = encodeURIComponent(line[0]);
        mapping[key] = line[1];
    }
    return mapping;
}

const mapping = conf2mapping();

function Text(text) {
    if (language() === 1) return text;
    let key = encodeURIComponent(text);
    if (mapping[key]) return mapping[key];
    console.log('未翻译的汉语---', text);
    return text;
}

//艾克米克系统后台
let ExercisesFooter = '©2021 Exercises';

let setting = {
    title: Text('Exercises系统后台'),
    authenticate: {
        loginReact: <GeneLogin/>,
        loginPath: '/programmer/jwt/',
        setPath: '/programmer/set_password/',
        headers: null,
        role: null,
        username: null,
        responseData: null,
        jwtToken: null,
        abstract_role_name: null,
        // logoImage: 'http://127.0.0.1:8000/favicon.ico',
        logoImage: '',
        english: false,
    },
    programmer: {
        webTitle: 'Exercises系统-programmer控制台',
        systemTitle: 'programmer控制台',
        footer: ExercisesFooter,
        menu__account: {
            name: '帐号',
            sub: {
                menu__base_account: {
                    name: '帐号一览',
                    table: {
                        path: 'programmer/user/',
                        columns: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            role: {title: '角色信息',},
                            cover: {title: '头像', type: 'imgHead',},
                            stop: {title: '停用状态', type: 'bool', textBool: ['已停用', '未停用',],},
                        },
                        columnsUpdate: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            password: {title: '密码',},
                            cover: {title: '头像', type: 'imgHead',},
                            stop: {title: '停用此账号', type: 'bool',},
                        },
                        text__update_title: '修改这个系统账号',
                        disableDownload: true,
                        sub__wechat: {
                            columnTitle: '绑定的微信',
                            path: 'programmer/sub_wechat/',
                            columns: {
                                avatarUrl: {title: '微信头像', type: 'imgShow',},
                                openid: {title: 'openid',},
                                nickName: {title: '微信昵称',},
                                gender: {
                                    title: '性别', type: 'map', map: {
                                        0: '未知',
                                        1: '男性',
                                        2: '女性',
                                    },
                                },
                                country: {title: '国家',},
                                province: {title: '省份',},
                                city: {title: '城市',},
                                language: {
                                    title: '语言', type: 'map', map: {
                                        'en': '英文',
                                        'zh_CN': '简体中文',
                                        'zh_TW': '繁体中文',
                                    },
                                },
                            },
                            columnsDetailLine: {
                                avatarUrl: {title: '微信头像', type: 'imgShow',},
                                openid: {title: 'openid',},
                                nickName: {title: '微信昵称',},
                                gender: {
                                    title: '性别', type: 'map', map: {
                                        0: '未知',
                                        1: '男性',
                                        2: '女性',
                                    },
                                },
                                country: {title: '国家',},
                                province: {title: '省份',},
                                city: {title: '城市',},
                                language: {
                                    title: '语言', type: 'map', map: {
                                        'en': '英文',
                                        'zh_CN': '简体中文',
                                        'zh_TW': '繁体中文',
                                    },
                                },
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                            text__delete: '解除绑定',
                        },
                        dynamicFilter: {
                            path: 'programmer/choices_role/',
                            searchKey: 'role_id',
                            text: '选择角色',
                        },
                        text__search_help: '通过昵称或账号搜索',
                    },
                },
                menu__roles: {
                    name: '角色一览',
                    table: {
                        path: 'programmer/role/',
                        columns: {
                            role_name: {title: '角色名',},
                            code: {title: '系统识别名',},
                        },
                        disableDownload: true,
                    },
                },
            },
        },
        menu__sys: {
            name: '系统',
            sub: {
                menu__setting: {
                    name: '系统设置',
                    table: {
                        path: 'programmer/setting/',
                        columns: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {title: '值',},
                            follow: {
                                title: '位置', type: 'map', map: {
                                    1: '跟随数据库',
                                    2: '跟随代码',
                                },
                            },
                            updated_at: {title: '更新时间', type: 'utcTime',},
                        },
                        columnsUpdate: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {
                                title: '值',
                                is_show: param => {
                                    let is_choice = tool.getAttribute(param, ['record', 'is_choice',],);
                                    return is_choice !== 1;
                                },
                            },
                            value_choice: {
                                title: '值',
                                type: 'singSearchChoice',
                                path: 'programmer/choices_setting_choices/',
                                is_show: param => {
                                    let is_choice = tool.getAttribute(param, ['record', 'is_choice',],);
                                    return is_choice === 1;
                                },
                            },
                            follow: {
                                title: '跟随', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '跟随数据库',},
                                    {value: 2, help: '跟随代码',},
                                ],
                            },
                        },
                        columnsCreate: {
                            info: {title: '项',},
                            key: {title: '项(机器友好)',},
                            value: {title: '值',},
                            follow: {
                                title: '跟随', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '跟随数据库',},
                                    {value: 2, help: '跟随代码',},
                                ],
                            },
                        },
                        disableDownload: true,
                        text__update_right: '设置',
                        sub__sub_setting_choices: {
                            columnTitle: '选项',
                            path: 'programmer/sub_setting_choices/',
                            columns: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            columnsCreate: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            columnsUpdate: {
                                value: {title: '值',},
                                info: {title: '帮助',},
                                index: {title: '排序索引',},
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                        },
                    },
                },
                menu__front: {
                    name: '前端静态文件',
                    table: {
                        path: 'programmer/front/',
                        columns: {
                            name: {title: '目录名',},
                            file_count: {title: '文件数量',},
                        },
                        columnsUpdate: {
                            order: {
                                title: '执行指令',
                                type: 'singleSmallChoice',
                                choices: [
                                    {value: 1, help: '发送到服务器',}
                                ],
                                path: 'programmer/choices_build_setting/',
                            },
                        },
                        disableDownload: true,
                        disableDelete: true,
                        text__update_right: '操作',
                    },
                },
                menu__backend: {
                    name: '数据库备份及恢复',
                    table: {
                        path: 'programmer/backend/',
                        columns: {
                            name: {title: '备份文件名', type: 'utcTime',},
                            filesize: {title: '文件大小',},
                            path: {title: '下载链接', type: 'file',},
                            name_origin: {title: '源文件名称',},
                        },
                        columnsCreate: {
                            order: {
                                title: '指令', type: 'singleSmallChoice', choices: [
                                    {value: '1', help: '备份数据库',},
                                    {value: '2', help: '恢复数据库',},
                                    {value: '3', help: '清理无效cloud文件',},
                                ], path: 'programmer/get_order_choices/',
                            },
                            file: {
                                title: '上传sql文件(or zip)',
                                type: 'file',
                                accept: ['.sql', '.zip',],
                                is_show: (param) => {
                                    let infoModalPath = tool.getAttribute(param, 'infoModalPath');
                                    let infoModal = tool.getStateSpace(infoModalPath);
                                    let order = tool.getAttribute(infoModal,
                                        ['paramModal', 'create__x', 'order',]
                                    )
                                    return (order === '2') || (order === '5');
                                },
                            },
                            password: {
                                title: '恢复密码', is_show: (param) => {
                                    let infoModalPath = tool.getAttribute(param, 'infoModalPath');
                                    let infoModal = tool.getStateSpace(infoModalPath);
                                    let order = tool.getAttribute(infoModal,
                                        ['paramModal', 'create__x', 'order',]
                                    )
                                    return (order === '2') || (order === '5');
                                },
                            },
                        },
                        text__create_button: '发送指令',
                        disableDownload: true,
                        disableCreate: true,
                        disableCopy: true,
                    },
                },
                menu__cloud: {
                    name: '抽象文件',
                    table: {
                        path: 'api_v1/cloud/',
                        columns: {
                            id: {title: 'ID',},
                            file_type: {title: '文件类型',},
                            path_local: {title: '本地路径',},
                            path_cloud: {title: '云端路径',},
                            code: {title: 'code',},
                            use_path: {
                                title: '文件位置', type: 'map', map: {
                                    1: '本地',
                                    2: '云端',
                                },
                            },
                            relation_count: {title: '关联数量',},
                        },
                        columnsDetailLine: {
                            file_type: {title: '文件类型',},
                            path_local: {title: '本地路径',},
                            path_cloud: {title: '云端路径',},
                            use_path: {
                                title: '文件位置', type: 'map', map: {
                                    1: '本地',
                                    2: '云端',
                                },
                            },
                            relation_count: {title: '关联数量',},
                        },
                        columnsUpdate: {
                            order: {
                                title: '命令', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '服务器端迁移到云端',},
                                    {value: 2, help: '云端迁移到服务器端',},
                                ],
                            },
                        },
                        columnsCreate: {
                            order: {
                                title: '命令', type: 'singleSmallChoice', choices: [
                                    {value: 1, help: '清理所有孤儿文件',},
                                    {value: 2, help: '全部服务器端迁移到云端',},
                                    {value: 3, help: '全部云端迁移到服务器端',},
                                ],
                            },
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                        disableDelete: true,

                        text__update_right: '局部指令',
                        text__create_button: '全局指令',
                        text__detail_right: '详细信息',

                        filters: {
                            choices: {
                                'all': {
                                    text: '全部',
                                },
                                'clear': {
                                    text: '孤儿文件',
                                },
                            },
                            default: 'all',
                        },
                    },
                },
            },
        },
        menu__robot: {
            name: '机器人',
            sub: {
                menu__robot_info: {
                    name: '机器人消息',
                    table: {
                        path: 'programmer/robot_info/',
                        columns: {
                            created_at: {title: '创建时间', type: 'utcTime',},
                            info: {title: '消息',},
                            info_type: {title: '消息类型',},
                            // robot_obj: {title: '文件类型',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                    },
                },
                menu__robot_public: {
                    name: '机器人全局信息',
                    table: {
                        path: 'programmer/robot_public/',
                        columns: {
                            last_year: {title: '年级任务最后执行时间', type: 'utcTime',},
                            executing_year: {
                                title: '正在执行年级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_month: {title: '月级任务最后执行时间', type: 'utcTime',},
                            executing_month: {
                                title: '正在执行月级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_day: {title: '日级任务最后执行时间', type: 'utcTime',},
                            executing_day: {
                                title: '正在执行日级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_hour: {title: '时级任务最后执行时间', type: 'utcTime',},
                            executing_hour: {
                                title: '正在执行时级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_minute: {title: '分级任务最后执行时间', type: 'utcTime',},
                            executing_minute: {
                                title: '正在执行分级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },
                        },
                        columnsDetailLine: {
                            last_year: {title: '年级任务最后执行时间', type: 'utcTime',},
                            executing_year: {
                                title: '正在执行年级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_month: {title: '月级任务最后执行时间', type: 'utcTime',},
                            executing_month: {
                                title: '正在执行月级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_day: {title: '日级任务最后执行时间', type: 'utcTime',},
                            executing_day: {
                                title: '正在执行日级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_hour: {title: '时级任务最后执行时间', type: 'utcTime',},
                            executing_hour: {
                                title: '正在执行时级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },

                            last_minute: {title: '分级任务最后执行时间', type: 'utcTime',},
                            executing_minute: {
                                title: '正在执行分级任务',
                                type: 'bool',
                                textBool: ['正在执行', '正在休眠',],
                            },
                        },
                        columnsUpdate: {
                            last_year: {title: '年级任务最后执行时间', type: 'time',},
                            executing_year: {title: '正在执行年级任务', type: 'bool',},

                            last_month: {title: '月级任务最后执行时间', type: 'time',},
                            executing_month: {title: '正在执行月级任务', type: 'bool',},

                            last_day: {title: '日级任务最后执行时间', type: 'time',},
                            executing_day: {title: '正在执行日级任务', type: 'bool',},

                            last_hour: {title: '时级任务最后执行时间', type: 'time',},
                            executing_hour: {title: '正在执行时级任务', type: 'bool',},

                            last_minute: {title: '分级任务最后执行时间', type: 'time',},
                            executing_minute: {title: '正在执行分级任务', type: 'bool',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                    },
                },
            },
        },
        menu__zip: {
            name: '源文件',
            table: {
                path: 'api_v1/zip/',
                columns: {
                    file_name: {title: '文件名',},
                    path: {title: '下载链接', type: 'file',},
                    created_at: {title: '上传时间', type: 'utcTime',},
                },
                columnsCreate: {
                    order: {
                        title: '命令', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '清空文件',},
                        ],
                    },
                },
                disableDownload: true,
                disableCreate: true,
                disableCopy: true,
                text__create_button: '提交命令',
                // disableDelete: true,
            },
        },
    },
    admin: {
        webTitle: 'Exercises系统-admin控制台',
        systemTitle: 'admin控制台',
        footer: ExercisesFooter,
        menu__account: {
            name: '帐号',
            sub: {
                menu__base_account: {
                    name: '帐号一览',
                    table: {
                        path: 'programmer/user/',
                        columns: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            role: {title: '角色信息',},
                            cover: {title: '头像', type: 'imgHead',},
                            stop: {title: '停用状态', type: 'bool', textBool: ['已停用', '未停用',],},
                        },
                        columnsUpdate: {
                            name: {title: '名字',},
                            username: {title: '系统帐号',},
                            password: {title: '密码',},
                            cover: {title: '头像', type: 'imgHead',},
                            stop: {title: '停用此账号', type: 'bool',},
                        },
                        text__update_title: '修改这个系统账号',
                        disableDownload: true,

                        dynamicFilter: {
                            path: 'programmer/choices_role/',
                            searchKey: 'role_id',
                            text: '选择角色',
                        },
                        text__search_help: '通过昵称或账号搜索',
                    },
                },
                menu__roles: {
                    name: '角色一览',
                    table: {
                        path: 'programmer/role/',
                        columns: {
                            role_name: {title: '角色名',},
                            code: {title: '系统识别名',},
                        },
                        disableDownload: true,
                    },
                },
                menu__managing: {
                    name: '编辑主管',
                    table: {
                        path: 'api_back/managing/',
                        columns: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                        },
                        columnsUpdate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        columnsCreate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        disableDownload: true,
                    },
                },
                menu__editor: {
                    name: '编辑员',
                    table: {
                        path: 'api_back/editor/',
                        columns: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                        },
                        columnsUpdate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        columnsCreate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        disableDownload: true,
                    },
                },
                menu__school: {
                    name: '校长',
                    table: {
                        path: 'api_back/school/',
                        columns: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                        },
                        columnsUpdate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        columnsCreate: {
                            cover: {title: "头像", type: 'imgHead',},
                            username: {title: "系统账号",},
                            name: {title: "名字",},
                            phone: {title: "手机号码",},
                            password: {title: "密码",},
                        },
                        disableDownload: true,
                    },
                },
            },
        },
        menu__book: {
            name: '基本预览及编辑权限',
            table: {
                path: 'api_v1/book/',
                columns: {
                    book_name: {title: '书名',},
                    database_name: {title: '体系',},
                    problem_count: {title: '题目数量(完成的/全部的)',},
                    state: {
                        title: '上架状态', type: 'bool', textBool: ['已上架', '未上架',],
                    },
                    position: {
                        title: '位置', type: 'map', map: {
                            1: '练习库',
                            2: '试题库',
                        }
                    },
                },
                columnsDetailLine: {
                    book_name: {title: '书名',},
                    database_name: {title: '体系',},
                    problem_count: {title: '题目数量(完成的/全部的)',},
                    state: {
                        title: '上架状态', type: 'bool', textBool: ['已上架', '未上架',],
                    },
                    position: {
                        title: '位置', type: 'map', map: {
                            1: '练习库',
                            2: '试题库',
                        }
                    },
                    active: {title: '是否活跃', type: 'bool', textBool: ['活跃', '不活跃',],},
                },
                disableDownload: true,
                disableDelete: true,
                sub__one: {
                    columnTitle: '一级目录',
                    path: 'api_v1/sub_one/',
                    columns: {
                        folder_name: {title: '一级目录名称',},
                        index: {title: '索引',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    disableDelete: true,
                    sub__two: {
                        columnTitle: '二级目录',
                        path: 'api_v1/sub_two/',
                        columns: {
                            folder_name: {title: '二级目录名称',},
                            index: {title: '索引',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                        disableDelete: true,
                        sub__problem: {
                            columnTitle: '题目',
                            path: 'api_v1/sub_problem/',
                            columns: {
                                id: {title: 'ID',},
                                index: {title: '题目索引', type: 'render', render: value => `第${value}题`,},
                                count: {title: '附件数量', type: 'render', render: value => `${value}张`,},
                                labels: {title: '标签',},
                                choices_x: {title: '选择题答案',},
                                degree: {title: '难度',},
                                area: {title: '占纸面积(8分之?)',},
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                            sub__img: {
                                columnTitle: '题目区',
                                path: 'api_v1/sub_img/',
                                columns: {
                                    index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                    img_obj: {title: '图片显示', type: 'imgHead',},
                                    img_name: {title: '文件名称',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                text__create_button: '增加图片',
                            },
                            sub__img_answer: {
                                columnTitle: '答案区',
                                path: 'api_v1/sub_img_answer/',
                                columns: {
                                    index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                    img_obj: {title: '图片显示', type: 'imgHead',},
                                    img_name: {title: '文件名称',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                disableDelete: true,
                                text__create_button: '增加图片',
                            },
                            sub__dims: {
                                columnTitle: '判分维度',
                                path: 'api_v1/sub_dims/',
                                columns: {
                                    dim_name: {title: '维度名称',},
                                    score: {title: '分值',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                disableDelete: true,
                            },
                        },

                    },

                },
                sub__editor: {
                    columnTitle: '编辑人员',
                    path: 'api_back/sub_editor/',
                    columns: {
                        cover: {title: "头像", type: 'imgHead',},
                        username: {title: "系统账号",},
                        name: {title: "名字",},
                        phone: {title: "手机号码",},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    text__create_button: '增加编辑员',
                    text__delete: '解除权限',
                },
                filters: {
                    choices: {
                        'inactive': {
                            text: '不活跃的',
                        },
                        'active': {
                            text: '活跃的',
                        },
                    },
                    default: 'active',
                },
                dynamicFilter: {
                    path: 'api_v1/choices_database/', // 选项加载于此路径
                    searchKey: 'database', //动态过滤器将使用这个关键字作为查询关键字 ?database=选中的值(choices.id)
                    text: '过滤数据集', // 动态过滤器初始显示的文字
                },
            },
        },
        menu__setting: {
            name: '系统设置',
            table: {
                path: 'api_v1/admin_setting/',
                columns: {
                    info: {title: '设置说明',},
                    value: {title: '值',},
                    updated_at: {title: '更新时间', type: 'utcTime',},
                    key: {title: '机器识别码',},
                },
                columnsUpdate: {
                    info: {title: '设置说明', help: '可以修改为易于自己理解的说明',},
                    value: {
                        title: '值',
                        is_show: param => {
                            let is_choice = tool.getAttribute(param, ['record', 'is_choice',],);
                            return is_choice !== 1;
                        },
                    },
                },
                disableDownload: true,
                disableCreate: true,
            },
        },
    },
    managing_editor: {
        webTitle: 'Exercises系统-编辑主管控制台',
        systemTitle: '编辑主管控制台',
        footer: ExercisesFooter,
        menu__editor: {
            name: '编辑员管理',
            table: {
                path: 'api_back/editor/',
                columns: {
                    cover: {title: "头像", type: 'imgHead',},
                    username: {title: "系统账号",},
                    name: {title: "名字",},
                    phone: {title: "手机号码",},
                },
                columnsUpdate: {
                    cover: {title: "头像", type: 'imgHead',},
                    username: {title: "系统账号",},
                    name: {title: "名字",},
                    phone: {title: "手机号码",},
                    password: {title: "密码",},
                },
                columnsCreate: {
                    cover: {title: "头像", type: 'imgHead',},
                    username: {title: "系统账号",},
                    name: {title: "名字",},
                    phone: {title: "手机号码",},
                    password: {title: "密码",},
                },
                disableDownload: true,
            },
        },
        menu__database: {
            name: '体系',
            table: {
                path: 'api_back/database/',
                columns: {
                    database_name: {title: "体系名称",},
                    index: {title: "排序索引",},
                },
                columnsDetailLine: {
                    database_name: {title: "体系名称",},
                    index: {title: "排序索引",},
                    database_type: {
                        title: "类型", type: 'map', map: {
                            1: "普通",
                            2: "TestPaper专用数据集",
                            3: "缺省值",
                        },
                    },
                },
                columnsUpdate: {
                    database_name: {title: "体系名称",},
                    index: {title: "排序索引",},
                    set_database_type: {title: "设置为缺省值", type: 'bool', help: "新建的书本自动设置为缺省值",},
                },
                columnsCreate: {
                    database_name: {title: "体系名称",},
                    index: {title: "排序索引",},
                },
                disableDownload: true,
                disableCreate: true,
                disableCopy: true,
                text__create_button: '新建体系',
                sub__dim: {
                    columnTitle: '体系分值维度',
                    path: 'api_back/sub_dim/',
                    columns: {
                        dim_name: {title: "分值维度名称",},
                    },
                    columnsUpdate: {
                        dim_name: {title: "分值维度名称",},
                    },
                    columnsCreate: {
                        dim_name: {title: "分值维度名称",},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    text__create_button: '增加判分维度',
                },
                sub__label: {
                    columnTitle: '标签管理',
                    path: 'api_back/sub_label/',
                    columns: {
                        label_name: {title: "标签代码",},
                        detail: {
                            title: "描述",
                            ellipsis: false,
                        },
                        creator: {title: '创建者',},
                    },
                    columnsUpdate: {
                        label_name: {title: "标签代码",},
                        detail: {title: "描述", type: 'text',},
                    },
                    columnsCreate: {
                        label_name: {title: "标签代码",},
                        detail: {title: "描述", type: 'text',},
                    },
                    disableDownload: true,
                    disableCreate: true,
                    disableCopy: true,
                    text__create_button: '新建标签',
                },
                sub__material: {
                    columnTitle: '资料方案',
                    path: 'api_back/sub_material/',
                    columns: {
                        id: {title: '方案编号',},
                        material_name: {title: '方案名称',},
                    },
                    columnsUpdate: {
                        material_name: {title: '方案名称',},
                    },
                    columnsCreate: {
                        material_name: {title: '方案名称',},
                    },
                    disableDownload: true,
                    disableCreate: true,
                    disableCopy: true,
                    text__create_button: '新建方案',
                    sub__hour: {
                        columnTitle: '课时管理',
                        path: 'api_back/sub_hour/',
                        columns: {
                            id: {title: "课时编号",},
                            index: {title: "第?课时", render: value => `第${value}课时`,},
                        },
                        columnsUpdate: {
                            index: {title: "第?课时",},
                        },
                        columnsCreate: {
                            index: {title: "第?课时",},
                        },
                        disableDownload: true,
                        disableCreate: true,
                        disableCopy: true,
                        text__create_button: '新建课时',
                        sub__task: {
                            columnTitle: '公共作业',
                            path: 'api_back/sub_task/',
                            columns: {
                                id: {title: "作业编号",},
                                describe: {title: "描述",},
                                detail_count: {title: "题目数量",},
                            },
                            columnsUpdate: {
                                describe: {title: "描述",},
                            },
                            columnsCreate: {
                                describe: {title: "描述",},
                            },
                            disableDownload: true,
                            disableCreate: true,
                            disableCopy: true,
                            text__create_button: '新建作业',
                            sub__detail: {
                                columnTitle: '题目',
                                path: 'api_back/sub_detail/',
                                columns: {
                                    id: {title: "作业题目编号",},
                                    problem_obj: {title: "问题ID",},
                                },
                                columnsCreate: {
                                    problem_obj: {title: "输入题目ID",},
                                },
                                disableDownload: true,
                                disableCreate: true,
                                disableCopy: true,
                                text__create_button: '添加题目',

                                sub__img: {
                                    columnTitle: '题目区',
                                    path: 'api_back/sub_img/',
                                    columns: {
                                        id: {title: '附件编号',},
                                        index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                        img_obj: {title: '图片显示', type: 'imgHead',},
                                        img_name: {title: '文件名称',},
                                    },
                                    disableDownload: true,
                                    disableCopy: true,
                                    disableCreate: true,
                                },
                                sub__img_answer: {
                                    columnTitle: '答案区',
                                    path: 'api_back/sub_img_answer/',
                                    columns: {
                                        id: {title: '附件编号',},
                                        index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                        img_obj: {title: '图片显示', type: 'imgHead',},
                                        img_name: {title: '文件名称',},
                                    },
                                    disableDownload: true,
                                    disableCopy: true,
                                    disableCreate: true,
                                    disableDelete: true,
                                },
                            },
                        },
                        sub__file: {
                            columnTitle: '课时附件',
                            path: 'api_back/sub_file/',
                            columns: {
                                id: {title: "课时附件编号",},
                                file_type: {
                                    title: "文件类型", type: 'map', map: {
                                        1: 'file',
                                        2: 'ppt',
                                        3: 'video',
                                        4: 'pdf',
                                        5: 'image',
                                    },
                                },
                                file_name: {title: "文件名称",},
                                file_obj: {title: "链接", type: 'file',},
                                index: {title: "索引",},
                            },
                            columnsCreate: {
                                file_type: {
                                    title: "文件类型", type: 'singleSmallChoice', choices: [
                                        {value: 1, help: 'file',},
                                        {value: 2, help: 'ppt',},
                                        {value: 3, help: 'video',},
                                        {value: 4, help: 'pdf',},
                                        {value: 5, help: 'image',},
                                    ],
                                },
                                file_obj: {title: "上传文件", type: 'file',},
                                index: {title: "索引", help: '输入一个数字',},
                            },
                            columnsUpdate: {
                                file_type: {
                                    title: "文件类型", type: 'singleSmallChoice', choices: [
                                        {value: 1, help: 'file',},
                                        {value: 2, help: 'ppt',},
                                        {value: 3, help: 'video',},
                                        {value: 4, help: 'pdf',},
                                        {value: 5, help: 'image',},
                                    ],
                                },
                                file_obj: {title: "上传文件", type: 'file',},
                                index: {title: "索引", help: '输入一个数字',},
                            },
                            disableDownload: true,
                            disableCreate: true,
                            disableCopy: true,
                            text__create_button: '添加附件',
                        },
                    },
                },
                sub__permission: {
                    columnTitle: '编辑权限',
                    path: 'api_back/sub_permission/',
                    columns: {
                        username: {title: '系统账号',},
                        name: {title: '昵称',},
                    },
                    columnsCreate: {
                        user_obj: {
                            title: '选择一个编辑员',
                            type: 'singSearchChoice',
                            path: 'api_back/choices_editor_user/',
                        },
                    },
                    disableDownload: true,
                    disableCreate: true,
                    disableCopy: true,
                    text__delete: '解除权限',
                    text__create_button: '增加权限',
                },
                text__search_help: '输入体系名称搜索',
            },
        },
        menu__batch: {
            name: '批量录入',
            table: {
                path: 'api_v1/batch/',
                columns: {
                    book_name: {title: '书名',},
                },
                columnsUpdate: {
                    file_obj: {
                        title: '上传压缩文件',
                        type: 'file',
                        accept: ['.xls', '.zip',],
                        help: '压缩文件应包含3层文件夹,举例说明:IB 2019 Oxford HL AI answer/Ch1/1A/1.jpg',
                    },
                    use_index_find_problem: {
                        title: '索引对齐',
                        type: 'bool',
                        help: '如果题目索引不是从1开始,请使用索引对齐功能',
                    },
                },
                columnsCreate: {
                    file_obj: {
                        title: '上传压缩文件',
                        type: 'file',
                        accept: ['.zip'],
                        help: '压缩文件应包含3层文件夹,举例说明:IB 2019 Oxford HL AI/Ch1/1A/1.jpg',
                    },
                },
                disableDownload: true,
                disableCreate: true,
                disableCopy: true,
                text__update_right: '上传答案文件',
                text__delete: '删除这本题册',
                text__create_button: '上传压缩文件创建整本书',
                sub__create_info: {
                    columnTitle: '批量题目日志',
                    path: 'api_v1/batch_info/',
                    columns: {
                        created_at: {title: '时间', type: 'utcTime',},
                        index: {title: '处理于第?秒', type: 'render', render: value => `第${value}秒`,},
                        info: {title: '消息',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                },
                sub__update_info: {
                    columnTitle: '批量答案日志',
                    path: 'api_v1/update_info/',
                    columns: {
                        created_at: {title: '时间', type: 'utcTime',},
                        index: {title: '处理于第?秒', type: 'render', render: value => `第${value}秒`,},
                        info: {title: '消息',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                },
                text__search_help: '通过书名搜索',
            },
        },
        _menu__zip: {
            name: '源文件',
            table: {
                path: 'api_v1/zip/',
                columns: {
                    file_name: {title: '文件名',},
                    path: {title: '下载链接', type: 'file',},
                    created_at: {title: '上传时间', type: 'utcTime',},
                },
                disableDownload: true,
                disableCreate: true,
                disableCopy: true,
                disableDelete: true,
            },
        },
        menu__book: {
            name: '基本预览及编辑权限',
            table: {
                path: 'api_v1/book/',
                columns: {
                    book_name: {title: '书名',},
                    database_name: {title: '体系',},
                    problem_count: {title: '题目数量(完成的/全部的)',},
                    state: {
                        title: '上架状态', type: 'bool', textBool: ['已上架', '未上架',],
                    },
                    position: {
                        title: '位置', type: 'map', map: {
                            1: '练习库',
                            2: '试题库',
                        }
                    },
                },
                columnsDetailLine: {
                    book_name: {title: '书名',},
                    database_name: {title: '体系',},
                    problem_count: {title: '题目数量(完成的/全部的)',},
                    state: {
                        title: '上架状态', type: 'bool', textBool: ['已上架', '未上架',],
                    },
                    position: {
                        title: '位置', type: 'map', map: {
                            1: '练习库',
                            2: '试题库',
                        }
                    },
                    active: {title: '是否活跃', type: 'bool', textBool: ['活跃', '不活跃',],},
                },
                columnsUpdate: {
                    book_name: {title: '书名',},
                    database_obj: {
                        title: '所属体系',
                        type: 'singSearchChoice',
                        path: 'api_v1/choices_database/',
                    },
                    state: {
                        title: '是否上架', type: 'singleSmallChoice', choices: [
                            {value: true, help: '上架',},
                            {value: false, help: '下架',}
                        ],
                    },
                    position: {
                        title: '位置', type: 'singleSmallChoice', choices: [
                            {value: 1, help: '练习库',},
                            {value: 2, help: '试题库',},
                        ],
                    },
                    active: {title: '是否活跃', type: 'bool',},
                },
                disableDownload: true,
                disableDelete: true,
                sub__one: {
                    columnTitle: '一级目录',
                    path: 'api_v1/sub_one/',
                    columns: {
                        folder_name: {title: '一级目录名称',},
                        index: {title: '索引',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    disableDelete: true,
                    sub__two: {
                        columnTitle: '二级目录',
                        path: 'api_v1/sub_two/',
                        columns: {
                            folder_name: {title: '二级目录名称',},
                            index: {title: '索引',},
                        },
                        disableDownload: true,
                        disableCopy: true,
                        disableCreate: true,
                        disableDelete: true,
                        sub__problem: {
                            columnTitle: '题目',
                            path: 'api_v1/sub_problem/',
                            columns: {
                                id: {title: 'ID',},
                                index: {title: '题目索引', type: 'render', render: value => `第${value}题`,},
                                count: {title: '附件数量', type: 'render', render: value => `${value}张`,},
                                labels: {title: '标签',},
                                choices_x: {title: '选择题答案',},
                                degree: {title: '难度',},
                                area: {title: '占纸面积(8分之?)',},
                            },
                            columnsCreate: {
                                index: {title: '题目索引',},
                                choices_x: {title: '选择题答案',},
                                degree: {title: '难度',},
                                area: {title: '占纸面积(8分之?)',},
                            },
                            columnsUpdate: {
                                index: {title: '题目索引',},
                                choices_x: {title: '选择题答案',},
                                degree: {title: '难度',},
                                area: {title: '占纸面积(8分之?)',},
                            },
                            disableDownload: true,
                            disableCopy: true,
                            disableCreate: true,
                            sub__img: {
                                columnTitle: '题目区',
                                path: 'api_v1/sub_img/',
                                columns: {
                                    index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                    img_obj: {title: '图片显示', type: 'imgHead',},
                                    img_name: {title: '文件名称',},
                                },
                                columnsCreate: {
                                    index: {title: '图片索引',},
                                    img_obj: {title: '图片文件', type: 'file',},
                                },
                                columnsUpdate: {
                                    prob_obj: {title: '迁移到此题目ID下面',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                text__create_button: '增加图片',
                            },
                            sub__img_answer: {
                                columnTitle: '答案区',
                                path: 'api_v1/sub_img_answer/',
                                columns: {
                                    index: {title: '图片索引', type: 'render', render: value => `第${value}张`,},
                                    img_obj: {title: '图片显示', type: 'imgHead',},
                                    img_name: {title: '文件名称',},
                                },
                                columnsCreate: {
                                    index: {title: '图片索引',},
                                    img_obj: {title: '图片文件', type: 'file',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                disableDelete: true,
                                text__create_button: '增加图片',
                            },
                            sub__dims: {
                                columnTitle: '判分维度',
                                path: 'api_v1/sub_dims/',
                                columns: {
                                    dim_name: {title: '维度名称',},
                                    score: {title: '分值',},
                                },
                                disableDownload: true,
                                disableCopy: true,
                                disableCreate: true,
                                disableDelete: true,
                            },
                        },

                    },

                },
                sub__editor: {
                    columnTitle: '编辑人员',
                    path: 'api_back/sub_editor/',
                    columns: {
                        cover: {title: "头像", type: 'imgHead',},
                        username: {title: "系统账号",},
                        name: {title: "名字",},
                        phone: {title: "手机号码",},
                    },
                    columnsCreate: {
                        editor_id: {
                            title: "选择编辑员",
                            type: 'singSearchChoice',
                            path: 'api_back/choices_editor/',
                        },
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    text__create_button: '增加编辑员',
                    text__delete: '解除权限',
                },
                jumps: [
                    ['/editor/', "跳转至编辑员界面",],
                ],
                filters: {
                    choices: {
                        'inactive': {
                            text: '不活跃的',
                        },
                        'active': {
                            text: '活跃的',
                        },
                    },
                    default: 'active',
                },
                dynamicFilter: {
                    path: 'api_v1/choices_database/', // 选项加载于此路径
                    searchKey: 'database', //动态过滤器将使用这个关键字作为查询关键字 ?database=选中的值(choices.id)
                    text: '过滤数据集', // 动态过滤器初始显示的文字
                },
                help: {
                    text: '编辑主管在这里可以进行全局操作.',
                },
                text__search_help: '通过书名搜索',
            },
        },
    },
    editor: {
        path: '/',
    },
    teacher: {
        path: '/',
    },
    student: {
        path: '/',
    },
    schoolmaster: {
        webTitle: '校长控制台',
        systemTitle: '校长控制台',
        footer: ExercisesFooter,
        menu__editor: {
            name: '班级管理',
            table: {
                path: 'api_back/class/',
                columns: {
                    class_name: {title: "班级名称",},
                    database_name: {title: "使用的体系",},
                    material_name: {title: "资料方案",},
                    teacher_teach_name: {title: "教学老师",},
                    teacher_correct_name: {title: "批改老师",},
                    class_score_average: {title: "班级平均分(百分制)",},
                    miss_count: {title: "[没交/应交]作业数量",},
                },
                columnsUpdate: {
                    class_name: {title: "班级名称",},
                    database_obj: {
                        title: "使用的体系",
                        type: 'singSearchChoice',
                        path: 'api_v1/choices_database/',
                    },
                    teacher_teach: {
                        title: "教学老师",
                        type: 'singSearchChoice',
                        path: 'api_back/choices_teacher_teach/',
                    },
                    teacher_correct: {
                        title: "批改老师",
                        type: 'singSearchChoice',
                        path: 'api_back/choices_teacher_correct/',
                    },
                    material_obj: {
                        title: "选择资料",
                        type: 'singleSmallChoice',
                        path: 'api_back/choices_material/',
                        help: '一旦提交,不能更改。',
                        is_show: param => {
                            const material_obj = tool.getAttribute(param, ['record', 'material_obj',]);
                            const database_obj = tool.getAttribute(param, ['record', 'database_obj',]);
                            return !(material_obj || !database_obj);
                        },
                    },
                },
                _columnsUpdate_1: {
                    class_name: {title: "班级名称",},
                    database_obj: {
                        title: "使用的体系",
                        type: 'singSearchChoice',
                        path: 'api_v1/choices_database/',
                    },
                    teacher_teach: {
                        title: "教学老师",
                        type: 'singSearchChoice',
                        path: 'api_back/choices_teacher_teach/',
                    },
                    teacher_correct: {
                        title: "批改老师",
                        type: 'singSearchChoice',
                        path: 'api_back/choices_teacher_correct/',
                    },
                },
                _columnsUpdate_2: {
                    material_obj: {
                        title: "载入资料",
                        type: 'singleSmallChoice',
                        path: 'api_back/choices_material/',
                    },
                },
                columnsCreate: {
                    class_name: {title: "班级名称",},
                    database_obj: {
                        title: "使用的体系",
                        type: 'singSearchChoice',
                        path: 'api_v1/choices_database/',
                    },
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                // disableUpdate: true,
                text__create_button: '创建班级',
                // text__update_part_right: '选择方案',
                sub__student: {
                    columnTitle: "本班学生",
                    path: 'api_back/sub_student/',
                    columns: {
                        name: {title: "名字",},
                        username: {title: "账号",},
                    },
                    columnsCreate: {
                        student_id: {
                            title: "选择学生",
                            type: "singSearchChoice",
                            path: "api_back/choices_student/",
                        },
                    },
                    disableDownload: true,
                    disableCopy: true,
                    disableCreate: true,
                    text__create_button: '增加学生',
                    text__delete: "移出",
                },
                sub__sub_hour: {
                    columnTitle: "本班课时",
                    path: 'api_back/schoolmaster_sub_hour/',
                    columns: {
                        index: {title: "索引", render: value => `第${value}课时`,},
                        execution_time: {title: "上课时间", type: 'utcTime',},
                        is_show: {title: "是否公布", type: 'bool', textBool: ['公布', '不公布',],},
                        database_hour_obj: {title: '载入于体系课时编号',},
                    },
                    columnsCreate: {
                        index: {title: "索引",},
                        execution_time: {title: "上课时间", type: 'time',},
                        is_show: {title: "是否公布", type: 'bool',},
                    },
                    columnsUpdate: {
                        index: {
                            title: "索引", is_show: param => {
                                const database_hour_obj = tool.getAttribute(
                                    param, ['record', 'database_hour_obj',]);
                                return !database_hour_obj;
                            },
                        },
                        execution_time: {title: "上课时间", type: 'time',},
                        is_show: {title: "是否公布", type: 'bool',},
                    },
                    disableDownload: true,
                    disableCopy: true,
                    // disableCreate: true,
                    // disableDelete: true,
                    text__create_button: '增加课时',
                    text__delete: "删除",
                    sub__task: {
                        columnTitle: '课时作业',
                        path: 'api_back/schoolmaster_sub_hour_task/',
                        columns: {
                            created_at: {title: "创建时间", type: 'chinaTime',},
                            describe: {title: "描述",},
                            teacher_obj: {title: "布置老师",},
                            state: {
                                title: "状态", type: 'map', map: {
                                    0: "未发布",
                                    1: "收取",
                                },
                            },
                            database_task_obj: {title: "复制于资料编号",},
                        },
                        columnsUpdate: {
                            describe: {
                                title: "描述", is_show: param => {
                                    const database_file_obj = tool.getAttribute(
                                        param, ['record', 'database_task_obj',]);
                                    return !database_file_obj;
                                },
                            },
                        },
                        disableDownload: true,
                        disableCreate: true,
                        disableCopy: true,
                        // disableDelete: true,
                        sub__detail: {
                            columnTitle: '题目',
                            path: 'api_back/schoolmaster_sub_detail/',
                            columns: {
                                index: {title: "排序索引",},
                                problem_obj: {title: "使用系统题目ID",},
                                database_detail_obj: {title: "复制于资料编号",},
                            },
                            disableDownload: true,
                            disableCreate: true,
                            disableCopy: true,
                            // disableDelete: true,
                        },
                    },
                    sub__file: {
                        columnTitle: '课时附件',
                        path: 'api_back/schoolmaster_sub_hour_file/',
                        columns: {
                            database_file_obj: {title: "复制于资料编号",},
                            file_type: {
                                title: "文件类型", type: 'map', map: {
                                    1: 'file',
                                    2: 'ppt',
                                    3: 'video',
                                    4: 'pdf',
                                    5: 'image',
                                },
                            },
                            file_name: {title: "文件名称",},
                            file_obj: {title: "链接", type: 'file',},
                            index: {title: "索引",},
                        },
                        columnsCreate: {
                            file_type: {
                                title: "文件类型", type: 'singleSmallChoice', choices: [
                                    {value: 1, help: 'file',},
                                    {value: 2, help: 'ppt',},
                                    {value: 3, help: 'video',},
                                    {value: 4, help: 'pdf',},
                                    {value: 5, help: 'image',},
                                ],
                            },
                            file_obj: {title: "上传文件", type: 'file',},
                            index: {title: "索引", help: '输入一个数字',},
                        },
                        columnsUpdate: {
                            file_type: {
                                title: "文件类型", type: 'singleSmallChoice', choices: [
                                    {value: 1, help: 'file',},
                                    {value: 2, help: 'ppt',},
                                    {value: 3, help: 'video',},
                                    {value: 4, help: 'pdf',},
                                    {value: 5, help: 'image',},
                                ],
                                is_show: param => {
                                    const database_file_obj = tool.getAttribute(
                                        param, ['record', 'database_file_obj',]);
                                    return !database_file_obj;
                                },
                            },
                            file_obj: {
                                title: "上传文件", type: 'file', is_show: param => {
                                    const database_file_obj = tool.getAttribute(
                                        param, ['record', 'database_file_obj',]);
                                    return !database_file_obj;
                                },
                            },
                            index: {
                                title: "索引", help: '输入一个数字', is_show: param => {
                                    const database_file_obj = tool.getAttribute(
                                        param, ['record', 'database_file_obj',]);
                                    return !database_file_obj;
                                },
                            },
                            hour_obj: {
                                title: "所属课时", help: '支持同班级迁移', type: 'singleSmallChoice',
                                path: 'api_back/choices_hour/', is_show: param => {
                                    const database_file_obj = tool.getAttribute(
                                        param, ['record', 'database_file_obj',]);
                                    return !database_file_obj;
                                },
                            },
                        },
                        disableDownload: true,
                        disableCreate: true,
                        disableCopy: true,
                        // disableDelete: true,
                        text__create_button: '添加附件',
                    },
                },
            },
        },
        menu__teacher: {
            name: '老师管理',
            table: {
                path: 'api_back/teacher/',
                columns: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                    per_teach: {title: "教学权限", type: 'bool', textBool: ['有', '无',],},
                    per_correct: {title: "批改权限", type: 'bool', textBool: ['有', '无',],},
                },
                columnsUpdate: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                    password: {title: "重置密码",},
                    per_teach: {title: "教学权限", type: 'bool',},
                    per_correct: {title: "批改权限", type: 'bool',},
                },
                columnsCreate: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                    password: {title: "设置密码",},
                    per_teach: {title: "教学权限", type: 'bool',},
                    per_correct: {title: "批改权限", type: 'bool',},
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: '创建账号',
            },
        },
        menu__student: {
            name: '学生管理',
            table: {
                path: 'api_back/student/',
                columns: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                },
                columnsUpdate: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                    password: {title: "重置密码",},
                },
                columnsCreate: {
                    username: {title: "账号",},
                    name: {title: "名字",},
                    password: {title: "设置密码",},
                },
                disableDownload: true,
                disableCopy: true,
                disableCreate: true,
                text__create_button: '创建账号',
            },
        },
    },
    selectedMenu: {},
    fieldMapping: fieldMapping,
    fieldDetailMapping: fieldDetailMapping,
    stateAffixMenu: true,
    // bottom: <Bottom/>,
};

export {setting, Text,}
