import React from 'react';
import {connect} from 'react-redux';
import {tool} from "../../../tools/tools";
import './dynamics.css';

import {Menu, Dropdown, Tooltip} from 'antd';


let lastUpdate = 0;

//多动态过滤器组件
class DynamicFiltersUnConnected extends React.Component {

    constructor(props) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    // 更新表格数据
    updateData(search) {
        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 获取搜索关键字
        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
        let searchKey = tool.getAttribute(dynamicFilter, 'searchKey',);

        // 设置加载状态及相关参数
        spacePrivate = Object.assign(spacePrivate, {
            stateTableOnload: 'dynamic',
            'searchKey': searchKey,
            'dynamicSearchWord': search,
        });

        tool.setStateSpace(spacePrivatePath, spacePrivate,)
    }

    updateChoices() {
        let now = (new Date()).valueOf();
        let diff = now - lastUpdate;
        if (diff < 3000) return null;
        lastUpdate = (new Date()).valueOf();

        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
        if (!dynamicFilter) return null;

        let path = tool.getAttribute(dynamicFilter, 'path',);
        let requestParam = {
            url: tool.path_join(path),
            success: data => {
                let results = tool.getAttribute(data, 'results', []);
                let dynamicFilterPath = spacePublicPath.concat(['dynamicFilter']);
                let spacePublic = tool.getStateSpace(spacePublicPath);
                let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilter');
                dynamicFilter = Object.assign(dynamicFilter, {
                    menu: results,
                },);
                tool.setStateSpace(dynamicFilterPath, dynamicFilter);
            },
            error: error => {
                tool.show_error(error);
            },
        };
        tool.get(requestParam);

    }

    handleMenuClick(event) {
        this.updateData(event.key);
    }

    getComponent(dynamicFilter,) {
        let text = tool.getAttribute(dynamicFilter, 'text',);
        let menu = tool.getAttribute(dynamicFilter, 'menu', [],);

        let item = [];
        for (const line of menu) {
            let id = line.id;
            let help = line.help;
            item.push(
                <Menu.Item key={id}>
                    {help}
                </Menu.Item>,
            );
        }
        menu = (
            <Menu onClick={this.handleMenuClick}>
                {item}
            </Menu>
        );


        return <Dropdown.Button
            overlay={menu}
            buttonsRender={([leftButton, rightButton]) => [
                <Tooltip title="tooltip" key="leftButton">
                    {leftButton}
                </Tooltip>,
                React.cloneElement(rightButton, {loading: false}),
            ]}
        >
            {text}
        </Dropdown.Button>
    }

    render() {
        this.updateChoices();
        let spacePublicPath = tool.getAttribute(this.props.param, 'spacePublicPath',);
        let spacePublic = tool.getStateSpace(spacePublicPath);
        let dynamicFilter = tool.getAttribute(spacePublic, 'dynamicFilters');
        if (!dynamicFilter) return null;
        return this.getComponent(dynamicFilter,);
    }

}

function state_to_dynamic(state) {

    return {};
}

function dynamic_to_state(_) {
    return {};
}

let DynamicFilters = connect(state_to_dynamic, dynamic_to_state)(DynamicFiltersUnConnected);

export {DynamicFilters,}
