import React from "react";
import {Table, Button, Popover, Input, Modal,} from 'antd';
import {LoadingOutlined,} from '@ant-design/icons';
import {UpdateTableLine} from "./table-upate-line";
import {UpdateTablePart} from "./table-upate-part";
import {CreateTablePart} from "./table-create-part";
import {CreateTable} from "./table-create";
import {CreateCopyTable} from "./table-create-copy";
import {CreateBatchTable} from "./table-create-batch";
import {DetailLine} from "./table-detail-line";
import {Download} from "./head/DownLoad";
import {Help} from "./head/help/help";
import {Filter} from "./head/filters/filters";
import {DynamicFilter} from "./head/dynamic_filter_type/dynamic_filter";
import {DynamicFilters} from "./head/dynamic_filters/dynamic_filters";
import {Count} from "./head/count/count";
import file from './help.png';

import {connect} from 'react-redux';
import {tool} from "../tools/tools";
import {getColumnRender} from "./columns/main";


import './power-table.css';
import {Jump} from "./head/jump/jump";

let lastSign = 0;


//智能表格 only触发器
class PowerTable extends React.Component {
    constructor(props) {
        super(props);
        this.addSubColumns = this.addSubColumns.bind(this);
        this.addTrigger = this.addTrigger.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.getDataTable = this.getDataTable.bind(this);
        this.getTableHead = this.getTableHead.bind(this);
        this.getSubTableReact = this.getSubTableReact.bind(this);
        this.getPagination = this.getPagination.bind(this);
        this.getSearchBorder = this.getSearchBorder.bind(this);
        this.getFatherId = this.getFatherId.bind(this);
    }

    // 生命周期: 组件生成
    componentDidMount() {
        this.updateData();
    }

    // 生命周期: state更新
    shouldComponentUpdate(state, _1, _2) {
        this.updateData();
        return state;
    }

    // 更新表格数据
    updateData() {
        setTimeout(() => {

            //公共空间路径及空间
            let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);


            // 私有空间路径
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let spacePrivate = tool.getStateSpace(spacePrivatePath);

            // 加载状态
            let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
            let stateTableOnload = tool.getStateSpace(stateTableOnloadPath);

            if (!stateTableOnload) return null;

            let page, page_size, search, filter;
            let isObject = Object.prototype.toString.call(stateTableOnload) === '[object Object]';
            if (stateTableOnload === 'page') { // 携带原来的page参数
                page = tool.getAttribute(spacePrivate, 'page');
                page_size = tool.getAttribute(spacePrivate, 'page_size');

                // 携带过滤器的值
                search = tool.getAttribute(spacePrivate, 'searchWord');

            } else if (stateTableOnload === 'search') {// 搜索式更新
                page = 1;
                page_size = tool.getAttribute(spacePrivate, 'page_size');
                search = tool.getAttribute(spacePrivate, 'searchWord');
            } else if (stateTableOnload === 'dynamic') {// 动态过滤器更新
                page = 1;
                page_size = tool.getAttribute(spacePrivate, 'page_size');
            } else if (stateTableOnload === 'progress') {// 进度条更新
                page = tool.getAttribute(spacePrivate, 'page');
                page_size = tool.getAttribute(spacePrivate, 'page_size');
                search = tool.getAttribute(spacePrivate, 'searchWord');
            } else if (stateTableOnload === 'filter') {// 过滤器更新
                page = 1;
            } else if (isObject) { // 参数附加在stateTableOnload里
                page = tool.getAttribute(stateTableOnload, 'page');
                page_size = tool.getAttribute(stateTableOnload, 'page_size');
            }

            // 获取请求路径
            let requestUrlPath = spacePublicPath.concat(['path']);
            let requestUrl = tool.getStateSpace(requestUrlPath);

            // 获取上级表的id father_id
            let urlParam = {};
            let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
            if (fatherRecord) urlParam['father_id'] = fatherRecord.id;

            // 全部附加过滤器参数
            let spacePublic = tool.getStateSpace(spacePublicPath);
            filter = tool.getAttribute(spacePrivate, 'filterValue');
            if (!filter) {
                filter = tool.getAttribute(spacePublic, ['filters', 'default',])
            }

            if (page) urlParam['page'] = page;
            if (page_size) urlParam['page_size'] = page_size;
            if (search) urlParam['search'] = search;
            if (filter) urlParam['filter'] = filter;

            // 携带动态过滤器的值
            let searchKey = tool.getAttribute(spacePrivate, 'searchKey',);
            if (searchKey) {
                let searchKey = tool.getAttribute(spacePrivate, 'searchKey');
                search = tool.getAttribute(spacePrivate, 'dynamicSearchWord');
                urlParam[searchKey] = search;
            }

            // 最后一次请求的刷新请求总是覆盖旧的刷新请求
            let mySign = lastSign + 1;
            lastSign = mySign;

            let url = tool.get_url(requestUrl, false, urlParam ? urlParam : {},);
            let requestParam = {
                url: url,
                success: responseData => {
                    // 在返回数据的这段时间,当前请求是不是依然是最后一次,如果不是就没必要刷新了
                    if (mySign < lastSign) return null;

                    let textSuccess = '数据已加载';

                    if (stateTableOnload === 'page') textSuccess = '数据已刷新';
                    let isObject = Object.prototype.toString.call(stateTableOnload) === '[object Object]';
                    if (isObject) textSuccess = `加载于第${page}页`;

                    // 最后一次更新的时间戳
                    let timestamp = new Date().getTime();

                    let spacePrivate = tool.getStateSpace(spacePrivatePath);
                    spacePrivate = Object.assign({}, spacePrivate, {
                        responseData: responseData,
                        stateTableOnload: false,
                        searchWaiting: false,
                        lastUpdateTime: timestamp,
                    });
                    if (page) spacePrivate['page'] = page;
                    if (page_size) spacePrivate['page_size'] = page_size;
                    if (stateTableOnload === 'progress') spacePrivate['selfProgress'] = false;
                    tool.setStateSpace(spacePrivatePath, spacePrivate);
                    if (stateTableOnload !== 'progress') {
                        tool.show_success(textSuccess);
                    }

                    // 右键使用提醒
                    let messageRight = tool.getStateSpace(['messageRightIsShow', 'messageRight']);
                    if (!messageRight) {
                        tool.show_info('温馨提醒: 在数据行上使用右键可以唤出操作菜单!');
                        tool.setStateSpace(['messageRightIsShow', 'messageRight'], true);
                    }

                },
                error: error => {
                    tool.show_error(error);
                    let spacePrivate = tool.getStateSpace(spacePrivatePath);
                    spacePrivate = Object.assign({}, spacePrivate, {
                        stateTableOnload: false,
                    });
                    tool.setStateSpace(spacePrivatePath, spacePrivate);
                }
            };


            // 上次更新时间
            spacePrivate = tool.getStateSpace(spacePrivatePath);
            let lastUpdateTime = tool.getAttribute(spacePrivate, 'lastUpdateTime');
            let nowTime = new Date().getTime();
            let stateUpdate = true;
            if (lastUpdateTime) {
                let diff = nowTime - lastUpdateTime;
                if (diff < 1000) stateUpdate = false;
            }
            if (stateUpdate) {
                tool.get(requestParam);
            } else {
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    stateTableOnload: false,
                });
                tool.setStateSpace(spacePrivatePath, spacePrivate);
            }
        }, 100);
    }

    // 得到列信息
    getColumns() {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        let columnsInfo = tool.getAttribute(spacePublic, 'columns');

        // 是否使用过滤器的字段
        let filterValue = tool.getAttribute(spacePrivate, 'filterValue');
        if (!filterValue) {
            filterValue = tool.getAttribute(spacePublic, ['filters', 'default',])
        }
        let filtersInfo = tool.getAttribute(spacePublic, 'filters');
        let filtersChoices = tool.getAttribute(filtersInfo, 'choices');
        let filterNow = tool.getAttribute(filtersChoices, filterValue);
        let filterColumns = tool.getAttribute(filterNow, 'columns');
        if (filterValue && filtersInfo && filtersChoices && filterNow && filterColumns) {
            columnsInfo = filterColumns;
        }

        let columns;

        if (!columnsInfo) {
            let tableData = tool.getAttribute(spacePrivate, ['responseData', 'results',],);
            if (!tableData || tableData.length < 1) return [];
            columns = [];
            let oneLine = tableData[0];
            for (let fieldName in oneLine) {
                if (!oneLine.hasOwnProperty(fieldName)) continue;

                columns.push({
                    title: fieldName,
                    dataIndex: fieldName,
                    key: fieldName,
                    // ellipsis: true,
                    render: (value, record, index) => {
                        return getColumnRender({
                            value: value,
                            record: record,
                            index: index,
                            spacePublicPath: spacePublicPath,
                            spacePublic: spacePublic,
                            columnsInfo: null,
                            columnInfo: null,
                            columnKey: fieldName,
                        });
                    },
                });
            }
        } else {
            columns = [];
            for (let columnKey in columnsInfo) {
                if (!columnsInfo.hasOwnProperty(columnKey)) continue;
                let columnInfo = columnsInfo[columnKey];
                let ellipsis = tool.getAttribute(columnInfo, 'ellipsis', true);

                columns.push({
                    title: columnInfo.title,
                    dataIndex: columnInfo.dataIndex ? columnInfo.dataIndex : columnKey,
                    key: columnKey,
                    ellipsis: ellipsis,
                    render: (value, record, index) => {
                        return getColumnRender({
                            value: value,
                            record: record,
                            index: index,
                            spacePublicPath: spacePublicPath,
                            spacePublic: spacePublic,
                            spacePrivatePath: spacePrivatePath,
                            columnsInfo: columnsInfo,
                            columnInfo: columnsInfo[columnKey],
                            columnKey: columnKey,
                        });
                    },
                });
            }
        }
        columns = this.addSubColumns(columns);
        columns = this.addTrigger(columns);

        return columns;
    }

    // 增加子表列
    addSubColumns(columns) {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);

        //得到此列的内容
        let getSubColumn = columnName => {
            //点击列中的 展开(或关闭)子表按钮
            let onClickSubColumn = (recordId, columnName) => {

                //私有空间 展开的行有哪些
                let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
                let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);
                if (!expandedRowKeys) expandedRowKeys = [];

                // 行空间--选中的列
                let spaceRowKey = `spaceRow__${recordId}`;
                let spaceRowPath = spacePrivatePath.concat([spaceRowKey,]);
                let nowSelectedColumnPath = spaceRowPath.concat(['nowSelectedColumn']);
                let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);

                //决定是展开还是关闭
                let state = (nowSelectedColumn === columnName) ? !tool.isInArray(expandedRowKeys, recordId) : true;

                //得到更新后的expandedRowKeys
                let newExpandedRowKeys = [];
                for (let expandedRowKey of expandedRowKeys) if (expandedRowKey !== recordId) newExpandedRowKeys.push(expandedRowKey);
                if (state) {
                    newExpandedRowKeys.push(recordId);

                    nowSelectedColumn = state ? columnName : null;
                    if (nowSelectedColumn) {
                        //子表的公共空间路径
                        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
                        let spaceSubPublicPath = spacePublicPath.concat(nowSelectedColumn);

                        //子表的私有空间路径
                        let spaceSubPrivateKey = `spacePrivate__${recordId}`;
                        let spaceSubPrivatePath = spaceSubPublicPath.concat(spaceSubPrivateKey);

                        //更新子表的加载状态为true  stateTableOnload
                        let spaceSubPrivate = tool.getStateSpace(spaceSubPrivatePath);

                        //子表的私有空间
                        spaceSubPrivate = Object.assign({}, spaceSubPrivate, {
                            stateTableOnload: true,
                        });

                        //更新子表的私有空间
                        tool.setStateSpace(spaceSubPrivatePath, spaceSubPrivate);
                    }

                }

                // 获取新的行空间
                let spaceRow = tool.getStateSpace(spaceRowPath);

                spaceRow = Object.assign({}, spaceRow, {
                    nowSelectedColumn: nowSelectedColumn,
                });

                //得到新的私有空间 更新行空间
                let spacePrivate = tool.getStateSpace(spacePrivatePath);
                spacePrivate = Object.assign({}, spacePrivate, {
                    expandedRowKeys: newExpandedRowKeys,
                    onlyRecordId: recordId, // 通知子表渲染函数仅渲染本行的子表格就行
                });
                spacePrivate[spaceRowKey] = spaceRow;

                tool.setStateSpace(spacePrivatePath, spacePrivate);
            };

            let columnTitle = tool.getAttribute(spacePublic, [columnName, 'columnTitle']);

            return {
                title: columnTitle,
                key: columnName,
                render: record => {

                    // 渲染is_show
                    let is_show = tool.getAttribute(spacePublic, [columnName, 'is_show',]);
                    if (is_show) {
                        let param = {
                            record: record,
                        };
                        let content = is_show(param);
                        if (content) return content;
                    }

                    let recordId = record.id;

                    //私有空间 展开的行有哪些
                    let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
                    let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);
                    if (!expandedRowKeys) expandedRowKeys = [];

                    // 行空间--选中的列
                    let spaceRowKey = `spaceRow__${recordId}`;
                    let nowSelectedColumnPath = spacePrivatePath.concat([spaceRowKey, 'nowSelectedColumn']);
                    let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);


                    //是展开还是关闭
                    let text = '展开';
                    let type = null;
                    let state = (nowSelectedColumn === columnName) ? !tool.isInArray(expandedRowKeys, recordId) : true;
                    if (!state) {
                        text = '关闭';
                        type = 'primary';
                    }
                    return <Button type={type} onClick={() => onClickSubColumn(recordId, columnName)}>{text}</Button>;
                },
            }
        };

        columns = Object.assign([], columns);

        for (let key in spacePublic) {

            if (!spacePublic.hasOwnProperty(key)) continue;
            if (key.slice(0, 5) === 'sub__') columns.push(getSubColumn(key));
        }

        return columns;
    }

    // 增加触发器
    addTrigger(columns) {
        //公共空间路径及空间
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        //得到此列的内容
        let getTriggerColumn = (columnName, func) => {
            const triggerPublicSpacePath = spacePublicPath.concat([columnName,]);

            let columnTitle = tool.getAttribute(spacePublic, [columnName, 'columnTitle']);
            let textButton = tool.getAttribute(spacePublic, [columnName, 'textButton'], '触发器',);
            let buttonType = tool.getAttribute(spacePublic, [columnName, 'buttonType'], 'link',);


            return {
                title: columnTitle,
                key: columnName,
                render: (text, record) => {
                    // 私有属性
                    const triggerPrivateSpacePath = triggerPublicSpacePath.concat([`private_${record.id}`]);
                    textButton = tool.getStateSpace(triggerPrivateSpacePath.concat(['textButton',]), textButton,);
                    buttonType = tool.getStateSpace(triggerPrivateSpacePath.concat(['buttonType',]), buttonType,);
                    const disabled = tool.getStateSpace(triggerPrivateSpacePath.concat(['disabled',]), false,);
                    // 触发器参数
                    const param = {
                        record: record,
                        columnName: columnName,
                        triggerPublicSpacePath: triggerPublicSpacePath,
                        spacePrivatePath: spacePrivatePath,
                        triggerPrivateSpacePath: triggerPrivateSpacePath,
                    };

                    return <Button
                        type={buttonType}
                        onClick={() => func(param)}
                        disabled={disabled}
                    >
                        {textButton}
                    </Button>;
                },
            }
        };

        columns = Object.assign([], columns);

        for (let key in spacePublic) {
            if (!spacePublic.hasOwnProperty(key)) continue;

            if (key.slice(0, 9) === 'trigger__') {
                let func = tool.getAttribute(spacePublic, [key, 'func']);
                if (!func) continue;
                columns.push(getTriggerColumn(key, func));
            }
        }

        return columns;
    }

    // 得到表格数据
    getDataTable() {
        //私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        return tool.getAttribute(spacePrivate, ['responseData', 'results',],);
    }

    // 得到子表的React
    getSubTableReact(record) {
        // 当前行空间 选中的列
        let recordId = tool.getAttribute(record, 'id');
        let spaceRowKey = `spaceRow__${recordId}`;
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let onlyRecordId = tool.getAttribute(spacePrivate, 'onlyRecordId',);
        let spaceRowPath = spacePrivatePath.concat([spaceRowKey]);
        let nowSelectedColumnPath = spaceRowPath.concat(['nowSelectedColumn']);
        let nowSelectedColumn = tool.getStateSpace(nowSelectedColumnPath);
        if (!nowSelectedColumn) return '空的';

        //子表的公共空间路径
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spaceSubPublicPath = spacePublicPath.concat(nowSelectedColumn);

        //子表的私有空间路径
        let spaceSubPrivateKey = `spacePrivate__${recordId}`;
        let spaceSubPrivatePath = spaceSubPublicPath.concat(spaceSubPrivateKey);

        // 是否自定义
        let spaceSubPublic = tool.getStateSpace(spaceSubPublicPath);
        let react = tool.getAttribute(spaceSubPublic, 'react');
        if (typeof react === "function") {
            let param = {
                spacePublicPath: spaceSubPublicPath,
                spacePrivatePath: spaceSubPrivatePath,
                spacePrivateKey: spaceSubPrivateKey,
                fatherRecord: record,
            };
            return react(param);
        }

        return <PowerTable param={{
            spacePublicPath: spaceSubPublicPath,
            spacePrivatePath: spaceSubPrivatePath,
            spacePrivateKey: spaceSubPrivateKey,
            fatherRecord: record,
            fatherLastClickRecordId: onlyRecordId, // 最后点击的行
        }}/>
    }

    // 右键点击行的触发
    getOnRow() {
        let onContextMenu = (event, record, _) => {
            // 本表的公共空间路径
            let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
            let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
            // 本表的私有空间路径
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);

            // 本行的行空间路径
            let recordId = record.id;
            let spaceRowKey = `spaceRow__${recordId}`;
            let spaceRowPath = spacePrivatePath.concat([spaceRowKey,]);

            // 获取新的右键空间
            let rightClickPath = ['rightClick'];
            let rightClickSpace = tool.getStateSpace(rightClickPath);
            rightClickSpace = Object.assign({}, rightClickSpace, {
                x: event.clientX,
                y: event.clientY,
                record: record,
                fatherRecord: fatherRecord,
                show: true,
                // sign: true,
                spacePublicPath: spacePublicPath,
                spacePrivatePath: spacePrivatePath,
                spaceRowPath: spaceRowPath,
            });
            tool.setStateSpace(rightClickPath, rightClickSpace);
        };

        return (record, index) => {
            return {
                // onClick: event => {},
                // onDoubleClick: event => {},
                onContextMenu: event => onContextMenu(event, record, index,),
                // onMouseEnter: () => onMouseEnter(record, index,),
                // onMouseLeave: () => onMouseLeave(),
            };
        }
    }

    // 创建按钮
    getCreateButtonReact() {
        // 当前行空间 选中的列
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spaceRowPath = tool.getAttribute(this, ['props', 'param', 'spaceRowPath',]);
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);

        // 是否显示
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        if (!columnsCreate) return null;


        let textPath = spacePublicPath.concat(['text__create_button']);
        let text = tool.getStateSpace(textPath);
        if (!text) text = '创建数据';

        let onClickCreate = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'Create',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };

        let useReact = () => {
            // let react__ = tool.getAttribute(columnsCreate, 'react__');
        };

        let useMenu = () => {
            let menu__ = tool.getAttribute(columnsCreate, 'menu__');
            let onClickMenu = tool.getStateSpace(['setting', 'onClickMenu',]);
            if (!onClickMenu) return null;
            onClickMenu(menu__, true);
        };

        let click = onClickCreate;
        let react__ = tool.getAttribute(columnsCreate, 'react__');
        if (react__) click = useReact;
        let menu__ = tool.getAttribute(columnsCreate, 'menu__');
        if (menu__) click = useMenu;

        return <Button onClick={click} type={'primary'}>{text}</Button>
    }

    // 批量创建按钮
    getCreateBatchButtonReact() {


        // 当前行空间 选中的列
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);

        let columnsCreateBatchPath = spacePublicPath.concat(['columnsCreateBatch']);
        let columnsCreateBatch = tool.getStateSpace(columnsCreateBatchPath);
        if (!columnsCreateBatch) return null;
        let columnsCreatePath = spacePublicPath.concat(['columnsCreate']);
        let columnsCreate = tool.getStateSpace(columnsCreatePath);
        if (!columnsCreate) return null;


        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spaceRowPath = tool.getAttribute(this, ['props', 'param', 'spaceRowPath',]);
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);


        let textPath = spacePublicPath.concat(['text__create_batch-button']);
        let text = tool.getStateSpace(textPath);
        if (!text) text = '批量创建数据';
        let onClickCreate = () => {
            let infoModalPath = ['infoModal'];
            let infoModal = tool.getStateSpace(infoModalPath);
            infoModal = Object.assign({}, infoModal, {
                nowType: 'CreateBatch',
                paramModal: {
                    spacePublicPath: spacePublicPath,
                    spacePrivatePath: spacePrivatePath,
                    spaceRowPath: spaceRowPath,
                    fatherRecord: fatherRecord,
                },
            });
            tool.setStateSpace(infoModalPath, infoModal);
        };
        return <Button onClick={() => onClickCreate()} type={'primary'}>{text}</Button>
    }

    // 刷新按钮
    getUpdateDataButton() {
        let text = '刷新数据';
        let onUpdateData = () => {
            let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
            let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
            tool.setStateSpace(stateTableOnloadPath, 'page');
        };
        return <Button onClick={() => onUpdateData()} type={'primary'}>{text}</Button>
    }

    // 搜索框
    getSearchBorder() {
        // 得到公共空间路径
        let spacePublicPath = tool.getAttribute(this, ['props', 'param', 'spacePublicPath',]);
        let spacePublic = tool.getStateSpace(spacePublicPath);

        // 搜索提示文字为
        let textSearchHelp = tool.getAttribute(spacePublic, 'text__search_help');
        // if (!textSearchHelp) textSearchHelp = '输入关键字进行搜索';
        if (!textSearchHelp) return null;

        // 得到私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let spacePrivate = tool.getStateSpace(spacePrivatePath);

        // 是否搜索等待中
        let searchWaiting = tool.getAttribute(spacePrivate, 'searchWaiting');

        // 关键词
        let searchWord = tool.getAttribute(spacePrivate, 'searchWord');

        // 点击搜索的触发
        let onclick = () => {
            let spacePrivate = tool.getStateSpace(spacePrivatePath);
            spacePrivate = Object.assign({}, spacePrivate, {
                stateTableOnload: 'search',
                searchWaiting: true,
            });
            tool.setStateSpace(spacePrivatePath, spacePrivate);
        };

        return (<div style={{display: 'inline-block', verticalAlign: 'super', marginLeft: '5px',}}>
            <Popover content={textSearchHelp}>
                <Input
                    addonBefore={"输入关键字"}
                    addonAfter={<div
                        style={{width: '40px', height: '100%',}}
                        onClick={onclick}
                    >
                        {searchWaiting ? <LoadingOutlined/> : '搜索'}
                    </div>}
                    style={{width: '250px',}}
                    onChange={
                        event => {
                            let value = event.target.value;
                            let searchWordPath = spacePrivatePath.concat(['searchWord']);
                            tool.setStateSpace(searchWordPath, value);
                        }
                    }
                    placeholder={textSearchHelp}
                    value={searchWord}
                />
            </Popover>
        </div>);
    }

    getFatherId() {
        let fatherId;
        fatherId = tool.getAttribute(this, ['props', 'param', 'fatherRecord', 'id',]);
        return fatherId;
    }

    // 表格头
    getTableHead() {
        // 创建按钮
        let createButtonReact = this.getCreateButtonReact();
        // 批量创建按钮
        let createBatchButtonReact = this.getCreateBatchButtonReact();
        // 刷新数据按钮
        let updateDataButton = this.getUpdateDataButton();
        // 搜索框
        let searchBorder = this.getSearchBorder();

        return (<div className={'powerTableHead'}>
            <div className={'powerTableHeadButton'}>{createButtonReact}</div>
            <div className={'powerTableHeadButton'}>{createBatchButtonReact}</div>
            <div className={'powerTableHeadButton'}>{updateDataButton}</div>
            <div className={'powerTableHeadButton'}>{searchBorder}</div>

            {/*下载组件*/}
            <div className={'powerTableHeadButton'}><Download param={
                this.props.param
            }/></div>

            {/*过滤器*/}
            <div className={'powerTableHeadButton'}><Filter param={
                this.props.param
            }/></div>

            {/*动态过滤器*/}
            <div className={'powerTableHeadButton'}><DynamicFilter
                param={this.props.param}
                getFatherId={this.getFatherId}
            /></div>

            {/*多动态过滤器*/}
            <div className={'powerTableHeadButton'}><DynamicFilters
                param={this.props.param}
                getFatherId={this.getFatherId}
            /></div>

            {/*跳转器*/}
            <div className={'powerTableHeadButton'}><Jump param={
                this.props.param
            }/></div>

            {/*帮助*/}
            <div className={'powerTableHeadButton'} style={{
                float: 'right',
                marginRight: '20px',
            }}><Help param={
                this.props.param
            }/></div>

            {/*数量*/}
            <div className={'powerTableHeadButton'} style={{
                float: 'right',
                marginRight: '20px',
            }}><Count param={
                this.props.param
            }/></div>

        </div>);
    }

    // 分页器
    getPagination() {
        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);

        let spacePrivate = tool.getStateSpace(spacePrivatePath);
        let count = tool.getAttribute(spacePrivate, ['responseData', 'count']);
        if (!count) count = 0;
        let page = tool.getAttribute(spacePrivate, ['page']);
        if (!page) page = 1;

        return {
            // defaultCurrent: this.state.defaultCurrent,
            // defaultCurrent: this.props.list.page,
            total: count,
            defaultPageSize: 10,
            onChange: (page, page_size) => {
                let onloadInfo = {
                    page: page,
                    page_size: page_size,
                };
                tool.setStateSpace(stateTableOnloadPath, onloadInfo);
            },
            current: page,
        };

    };

    // 表格尾巴
    getFooter() {
        let fatherRecord = tool.getAttribute(this, ['props', 'param', 'fatherRecord',]);
        if (fatherRecord) return null;
        return this.getTableHead();
        // let buttonCreate = this.getCreateButtonReact();
        // let buttonCreateBatch = this.getCreateBatchButtonReact();
        // return (<div>
        //     <div className={'powerTableFooterButton'}>{buttonCreate}</div>
        //     <div className={'powerTableFooterButton'}>{buttonCreateBatch}</div>
        // </div>);
    }


    render() {
        let columns = this.getColumns();
        let tableData = this.getDataTable();

        // 私有空间路径
        let spacePrivatePath = tool.getAttribute(this, ['props', 'param', 'spacePrivatePath',]);
        let stateTableOnloadPath = spacePrivatePath.concat(['stateTableOnload']);
        let stateTableOnload = tool.getStateSpace(stateTableOnloadPath);
        if (stateTableOnload === null) stateTableOnload = false;
        if (stateTableOnload) stateTableOnload = true;

        // 获取展开的行 spacePrivate.expandedRowKeys
        let expandedRowKeysPath = spacePrivatePath.concat(['expandedRowKeys']);
        let expandedRowKeys = tool.getStateSpace(expandedRowKeysPath);

        // 鼠标与行交互的相关触发
        let onRow = this.getOnRow();

        // 表格头
        let tableHead = this.getTableHead();

        // 分页器
        let pagination = this.getPagination();

        return (<div className={'powerTable'}>
            <Table
                // scroll={{ x: 1500, y: 300 }}
                loading={stateTableOnload}//加载中 预留
                // summary={(currentData) =><div>xxx</div>} //总结 预留
                title={(_) => tableHead}//标题 预留
                showHeader={true}
                onRow={onRow}
                // scroll={true}
                // expandedRowClassName={() => 'subTable'}
                dataSource={tableData}
                columns={columns}
                rowKey={'id'}
                expandedRowKeys={expandedRowKeys}
                expandIcon={() => null}
                expandable={{
                    expandedRowRender: record => this.getSubTableReact(record),
                }}
                pagination={pagination}
                footer={() => this.getFooter()}
                // footer={() => tableHead}
            />
        </div>);
    }
}

function mapStateToTable(state) {
    return {
        setting: state.setting,
        rightClick: state.rightClick,
    }
}

PowerTable = connect(mapStateToTable)(PowerTable);


// 右键提醒
class RightMessage extends React.Component {
    constructor(props) {
        super(props);
        this.setClose = this.setClose.bind(this);
    }

    setClose() {
        tool.setStateSpace(['messageRightIsShow',], {
            messageRight: true,
            messageRightIsShow: '1',
        });
    }

    render() {
        let state = true;
        let messageRightIsShow = tool.getStateSpace(['messageRightIsShow', 'messageRightIsShow',]);
        if (messageRightIsShow === '1') state = false;

        return (<div>
            <Modal
                title={'温馨提醒'}
                visible={state}
                okText={'知道了!'}
                cancelText={'下次不再提醒'}
                onOk={() => this.setClose()}
                onCancel={() => {
                    this.setClose();
                    localStorage.setItem('messageRightIsShow', '1');
                }}
            >
                表格点击右键可以唤出菜单!
                <img src={file} alt={'图片加载失败:表格点击右键可以唤出菜单.'}/>
            </Modal>
        </div>);
    }
}

function mapStateToMessage(state) {
    return {
        messageRightIsShow: state.messageRightIsShow,
    }
}

RightMessage = connect(mapStateToMessage)(RightMessage);

//根表格
class RootTable extends React.Component {


    render() {
        let spaceMenuPathStore = ['setting', 'selectedMenu', 'selectedSpacePath',];


        let spaceMenuPath = tool.getStateSpace(spaceMenuPathStore);
        let spaceMenu = tool.getStateSpace(spaceMenuPath);
        let react = tool.getAttribute(spaceMenu, 'react');


        let spacePublicPath = spaceMenuPath.concat(['table']);

        let spacePrivateKey = `spacePrivate__root`;
        let spacePrivatePath = spacePublicPath.concat([spacePrivateKey]);

        if (typeof react === "function") {
            let param = {
                spacePublicPath: spacePublicPath,
                spacePrivatePath: spacePrivatePath,
                spacePrivateKey: spacePrivateKey,
            };
            return react(param);
        }
        if (react) return react;

        return (<div className={'rootTableBack'}>
            <RightMessage/>
            <PowerTable param={{
                spacePublicPath: spacePublicPath,
                spacePrivatePath: spacePrivatePath,
                spacePrivateKey: spacePrivateKey,
            }}/>
            <DetailLine/>
            <UpdateTableLine/>
            <UpdateTablePart/>
            <CreateTablePart/>
            <CreateTable/>
            <CreateCopyTable/>
            <CreateBatchTable/>
        </div>);
    }
}

function mapStateToRoot(state) {
    return {
        setting: state.setting,
    }
}

RootTable = connect(mapStateToRoot)(RootTable);

export {PowerTable, RootTable,};
