import React from 'react';
import ReactDOM from 'react-dom';
import {Provider,} from 'react-redux';
import {ComponentLayout} from "./apps/layout/layout";
import {Login,} from "./apps/authenticate/login";
import {RightClick} from "./apps/right-click/right-click";

import {setting} from "./setting";
import {store} from "./apps/store/store";
import {tool} from "./apps/tools/tools";
import {connect} from 'react-redux';

import 'antd/dist/antd.css';


//显示布局还是显示登录
class Root extends React.Component {
    getNowPage() {
        let nowPage = tool.getStateSpace(['nowPage',]);
        if (!nowPage) return null;
        let react = tool.getStateSpace(['setting', 'pageMap', nowPage, 'react',]);
        if (!react) return null;

        return react;
    }

    getRoot() {
        let nowPage = this.getNowPage();
        if (nowPage) return nowPage;


        let headers = tool.getStateSpace(['setting', 'authenticate', 'headers',]);
        let choiceRole = tool.getStateSpace(['setting', 'authenticate', 'choiceRole',]);
        if ((!headers) || (choiceRole)) {
            let loginReact;
            loginReact = tool.getStateSpace(
                ['setting', 'authenticate', 'loginReact',],
                <Login/>,
            );
            return loginReact;
        }
        return <ComponentLayout/>;
    }

    getPath() {
        let path = tool.getAttribute(window, ['location', 'pathname',]);
        path = path.split('/');
        return path[1];
    }

    render() {
        let root = this.getRoot();
        let path = this.getPath();
        if (!path) path = 'defaultRoot';

        let router = {
            defaultRoot: (<div>
                {root}
                <RightClick/>
            </div>),
        };

        let router_custom = tool.getAttribute(setting, 'router');
        let bottom = tool.getAttribute(setting, 'bottom', null,);
        router = Object.assign({}, router, router_custom);
        if (!router[path]) return router['defaultRoot'];
        return <div>
            {router[path]}
            <div>
                {bottom ? bottom : null}
            </div>

        </div>;
    }
}

function mapStateToRoot(state) {
    return {
        authenticate: state.setting.authenticate,
        nowPage: state.nowPage,
    }
}

Root = connect(mapStateToRoot)(Root);

ReactDOM.render(<Provider store={store}><Root/></Provider>, document.getElementById('root'));


window.onload = function () {

    document.oncontextmenu = () => false;

    document.onmousedown = event => {
        if (event.button === 2) return null;
        let rightClickPath = ['rightClick',];
        let rightClick = tool.getStateSpace(rightClickPath);

        let sign = tool.getAttribute(rightClick, 'sign');
        if (sign) return null;

        rightClick = Object.assign({}, rightClick, {
            show: false,
        });
        tool.setStateSpace(rightClickPath, rightClick);
        return null;
    };

    tool.setTitle(setting.title);
};
