import React from "react";

class Bottom extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "48px",
        }}
      >
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style={{ color: "#bbb" }}
        >
          沪ICP备2021017669号-1
        </a>
      </div>
    );
  }
}

export { Bottom };
